<script setup>
import { useNuxtApp } from '#imports';

const { $tp } = useNuxtApp();
</script>
<template>
  <section class="stub container">
    <div class="stub__content">
      <div class="stub__text">
        <p class="stub__font">
          {{ $tp('Переверните телефон в вертикальное положение') }}
        </p>
      </div>

      <img class="stub__image" src="/images/stub.svg" alt="Icon" />
    </div>
  </section>
</template>
<style scoped lang="scss">
.stub {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: em(20);
  background-color: $color-yellow-1;

  &::before {
    position: absolute;
    bottom: em(8);
    left: 50%;
    display: block;
    width: em(226);
    height: em(5);
    content: '';
    background: $color-black;
    border-radius: em(100);
    transform: translateX(-50%);
  }

  &__font {
    font-size: em(30);
    font-weight: 700;
    line-height: 1;
    color: $color-white;
    letter-spacing: -0.03em;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__text {
    max-width: em(470);
  }

  &__image {
    flex-shrink: 0;
    width: em(188);
    height: em(76);
    margin-top: em(24);
  }
}
</style>
