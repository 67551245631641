<script setup>
import { computed, ref, useNuxtApp } from '#imports';

import { useAdult } from '@/composables/useAdult';

import UiButton from '@/components/ui/button/UiButton.vue';
import AuthLayout from '@/components/layout/AuthLayout.vue';

const emit = defineEmits(['change']);

const { $tp } = useNuxtApp();
const adultComposable = useAdult();

const adult = ref(undefined);

const isChooseOption = computed(() => {
  return adult.value !== undefined;
});

function toggleAdultValue(value) {
  adult.value = value;

  adultComposable.setAdultValue(value);

  emit('change');
}
</script>
<template>
  <AuthLayout class="adult-section" is-hidden-button-help>
    <template #content>
      <transition name="fade" mode="out-in">
        <div v-if="!isChooseOption" class="adult-section__content">
          <div class="adult-section__heading">
            <h3 class="adult-section__font adult-section__font--heading">
              {{ $tp('Добро пожаловать на Discovermore.ru') }}
            </h3>
          </div>

          <div class="adult-section__description">
            <p
              class="adult-section__font adult-section__font--description"
              v-html="
                $tp(
                  'Вы находитесь на официальном сайте бренда Camel.<br><br> На платформе мы познакомим вас с информацией о наших табачных продуктах, а также предоставим доступ к дополнительным информационным материалам. Подтвердите свой возраст для доступа к платформе.',
                )
              "
            />
          </div>

          <div class="adult-section__actions">
            <UiButton
              class="adult-section__button"
              text="Мне есть 18 лет"
              @click="toggleAdultValue(true)"
            />

            <UiButton
              class="adult-section__button"
              text="Мне нет 18 лет"
              theme="grey"
              @click="toggleAdultValue(false)"
            />
          </div>
        </div>

        <div
          v-else
          class="adult-section__content adult-section__content--small"
        >
          <div class="adult-section__heading">
            <h3 class="adult-section__font adult-section__font--heading">
              Сайт не доступен
            </h3>
          </div>

          <div class="adult-section__description">
            <p class="adult-section__font adult-section__font--description">
              {{
                $tp(
                  'Наша продукция предназначена только для совершеннолетних потребителей табачной продукции.',
                )
              }}
            </p>
          </div>
        </div>
      </transition>
    </template>
  </AuthLayout>
</template>
<style scoped lang="scss">
.adult-section {
  &__font {
    &--heading {
      font-size: em(60);
      font-weight: 600;
      line-height: 1;
      letter-spacing: -0.03em;

      @include media-breakpoint-down(sm) {
        font-size: em(30);
        font-weight: 700;
      }
    }

    &--description {
      font-size: em(18);
      line-height: 1.3;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    gap: em(36);
    width: em(800);
    padding: em(64) em(96);
    text-align: center;
    background-color: $color-white;
    border-radius: em(48);

    @include media-breakpoint-down(sm) {
      gap: em(24);
      width: 100%;
      padding: em(32) em(24);
      border-radius: em(32);
    }
  }

  &__actions {
    display: flex;
    gap: em(8);
    align-items: center;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }

  &__button {
    width: calc(50% - #{em(4)});

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
}
</style>
