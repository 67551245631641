<template>
  <Resize ref="rootRef" class="layouts-default" :class="className">
    <slot class-name="layouts-default__view" />

    <transition name="fade" mode="out-in">
      <SectionsAdult
        v-if="showAdult"
        ref="adultSectionRef"
        class="layouts-default__adult"
        @change="checkAdultSectionState"
      />
    </transition>

    <transition name="fade" mode="out-in">
      <SectionsSiteUnavailable
        v-if="siteUnavailable"
        ref="siteUnavailableRef"
        class="layouts-default__site-unavailable"
      />
    </transition>

    <Transition mode="out-in" name="fade">
      <Cookie
        v-if="showCookie"
        class="layouts-default__cookie"
        @select-cookie="hideCookieSection"
      />
    </Transition>

    <Stub v-if="showStub" ref="stubRef" class="layouts-default__stub" />
  </Resize>
</template>

<script setup>
import { ref, watch, onMounted } from '#imports';
import { useCookie } from 'nuxt/app';

import { useAdult } from '@/composables/useAdult';
import { useScrollLock } from '@/composables/useScrollLock';

import { useUserStore } from '@/stores/user';

import { USER_ROLES } from '@/configs/user-roles';

import Resize from '@/components/common/CResize.vue';
import SectionsAdult from '@/components/sections/Adult.vue';
import Cookie from '@/components/sections/Cookie.vue';
import SectionsSiteUnavailable from '@/components/sections/SiteUnavailable.vue';
import Stub from '@/components/sections/Stub.vue';

defineProps({
  className: {
    type: String,
    default: undefined,
  },
});

const adultComposable = useAdult();
const userStore = useUserStore();
const scrollLock = useScrollLock();

//<editor-fold desc="ADULT">
const showAdult = ref(true);
const adultSectionRef = ref(null);

checkAdultSectionState();

function checkAdultSectionState() {
  showAdult.value =
    !adultComposable.isChooseAdult() || !adultComposable.isAdult();
}

//</editor-fold>

//<editor-fold desc="COOKIE">
const showCookie = ref(true);

checkCookieSectionState();

function checkCookieSectionState() {
  const adultCookie = useCookie('cookie');
  const hasCookie = adultCookie.value !== undefined;
  const isAcceptCookie = adultCookie.value;
  const isSelectedCookie = userStore.isSelectedCookie;

  showCookie.value = !hasCookie || (!isAcceptCookie && !isSelectedCookie);
}
function hideCookieSection() {
  showCookie.value = false;
}
//</editor-fold>

//<editor-fold desc="SITE_UNAVAILABLE">
const siteUnavailable = ref(false);
const siteUnavailableRef = ref(null);

watch(
  () => userStore.user?.role,
  (value) => {
    if (!value) return;

    if (value === USER_ROLES.BLOCKED) {
      siteUnavailable.value = true;
    }
  },
  {
    immediate: true,
  },
);

watch(
  () => siteUnavailable.value,
  () => {
    if (process.server) return;

    if (siteUnavailable.value) scrollLock.lock(siteUnavailableRef.value?.$el);
  },
  {
    immediate: true,
  },
);
//</editor-fold>

//<editor-fold desc="STUB">
const rootRef = ref(null);
const stubRef = ref(null);
const showStub = ref(false);
const resizeObserver = ref(null);

onMounted(() => {
  addResizeObserver();
});

function addResizeObserver() {
  resizeObserver.value = new ResizeObserver(() => {
    checkStub();
  });

  resizeObserver.value.observe(rootRef.value?.$el);
}
function checkStub() {
  showStub.value = window.matchMedia(
    '(max-width: 951px) and (orientation: landscape)',
  ).matches;
}

watch(
  () => showStub.value,
  () => {
    if (process.server) return;

    scrollLock[showStub.value ? 'lock' : 'unlock']();
  },
  {
    immediate: true,
  },
);
//</editor-fold>
</script>

<style scoped lang="scss">
.layouts-default {
  $parent: &;

  display: flex;
  flex-direction: column;

  &:deep(#{$parent}__view) {
    flex-grow: 1;
  }

  &__adult {
    position: fixed !important;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100vw;
    height: 100%;
    overflow: hidden auto;
  }

  &__cookie {
    position: fixed !important;
    bottom: 0;
    left: em(32);
    z-index: 99;

    @include media-breakpoint-down(sm) {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__site-unavailable {
    position: fixed !important;
    top: 0;
    left: 0;
    z-index: 199;
    width: 100%;
    height: 100%;
    overflow: hidden auto;
  }

  &__stub {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    display: flex;
  }
}
</style>
