<script setup>
import { useNuxtApp, useCookie } from '#imports';
import { useUserStore } from '@/stores/user';
import { usePolicePopup } from '@/composables/usePolicePopup';

import UiButtonIcon from '@/components/ui/button/UiButtonIcon.vue';
import { police } from '@/configs/police';

const { openPolicePopup } = usePolicePopup();
const { $tp } = useNuxtApp();
const userStore = useUserStore();

const emit = defineEmits(['selectCookie']);

function selectCookie(value) {
  const adultCookie = useCookie('cookie');

  adultCookie.value = Boolean(value);
  userStore.setCookieState(value);

  emit('selectCookie');
}
</script>
<template>
  <div class="cookie-section">
    <div class="cookie-section__info">
      <p class="cookie-section__font cookie-section__font--info">
        {{ $tp('Мы используем') }}

        <span
          role="button"
          type="button"
          @click="openPolicePopup(police.privacyPolicy)"
        >
          Cookies
        </span>

        {{
          $tp(
            'и сервис веб-аналитики Яндекс Метрики для правильной работы сайта',
          )
        }}
      </p>
    </div>

    <div class="cookie-section__action">
      <UiButtonIcon
        class="cookie-section__button"
        text="Понятно"
        @click="selectCookie(true)"
      />
    </div>
  </div>
</template>
<style scoped lang="scss">
.cookie-section {
  $parent: &;

  display: flex;
  flex-direction: column;
  gap: em(16);
  width: em(320);
  padding: em(16) em(24);
  background-color: $color-white;
  border-radius: em(36) em(36) 0 0;
  box-shadow: $shadow-main;

  @include media-breakpoint-down(sm) {
    width: 100%;
    padding: em(16) em(24);
  }

  &__font {
    &--info {
      font-size: em(13);
      line-height: 1.2;

      &:deep(span) {
        font-weight: 700;
        cursor: pointer;

        @include hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__info {
    text-align: center;
  }

  &__action {
    display: flex;
  }

  &__button {
    width: 100%;
  }
}
</style>
