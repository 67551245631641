import { useCookie } from '#imports';

import { ref } from '#imports';

const adultKey = 'adult';
// переменная adultState нужна для одно общего состояния так как useAdult
// вызывается в разных местах и при этом переменная adultCookie создается
// для каждого вызова своя.
const adultState = ref(undefined);
// const userStore = useUserStore();

export const useAdult = () => {
  const adultCookie = useCookie(adultKey);
  adultState.value = adultCookie.value;
  function isChooseAdult() {
    return adultState.value !== undefined;
  }

  function isAdult() {
    return adultState.value;
  }

  function setAdultValue(value) {
    adultState.value = Boolean(value);
    adultCookie.value = Boolean(value);
  }

  return {
    isChooseAdult,
    isAdult,
    setAdultValue,
  };
};
